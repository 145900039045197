import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const NotFoundPageTemplate = ({
  data: { datoCmsNotFound },
  pageContext: { locale },
}) => {
  const { seoMetaTags, slugLocales, title, banner, modularBlocks } =
    datoCmsNotFound;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      noIndex={true}
      page={datoCmsNotFound}
    >
      <main>
        <Banner
          heading={title}
          text={banner.text}
          link={banner.link}
          image={banner.image}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export const NotFoundPageTemplateQuery = graphql`
  query NotFoundPageTemplateQuery($id: String!) {
    datoCmsNotFound(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      banner {
        ...BannerFragment
      }
      modularBlocks {
        ...ContentModularBlockFragment
      }
    }
  }
`;

export default NotFoundPageTemplate;
